module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"name","short_name":"short name","start_url":"/","background_color":"#ffffff","theme_color":"#111111","display":"minimal-ui","icon":"src/assets/svg/humanrights-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3d48f1e46dd51f926a980e29d92c7c80"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T9D4MM8","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://f90c1d46e3c9449c90db8e06855047cd@o421518.ingest.sentry.io/5447021","traceSampleRate":0.1,"environment":"Production"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
